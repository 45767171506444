@media (max-width: 768px) {
    .circle-container {
        display: none;
    }

    .paragraph-container {
        margin: 0 auto 5%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30%;
        border-radius: 10%;
    }

    .row {
        justify-content: center;
    }

    .paragraph {
        margin: 3%;
        max-height: 80%;

    }

    .title {
        display: flex;
        justify-content: center;
        margin-top: 0;
    }

    .ml-1 {
        display: none;
    }
}

.paragraph-container {
    height: max-content;
    background-color: rgb(44, 62, 80, 0.3);
    padding: 2%;
    border-radius: 10%;
}

.icon {
    font-size: 200%;
}

.ml-1 {
    font-size: large;
}