.icon-flex {
    transition: width 0.3s ease;
    /* Geçiş efekti */
}


.icon {
    transition: font-size 0.5s ease;
}

.icon:hover {
    font-size: 48px;
}


.button-bottom {
    background-color: lightgrey;
}


.welcome-div {
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@keyframes buttonAppear {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* Home.css */
.appear-animation {
    animation: buttonAppear 0.5s ease-in-out;
}

/* Home.css */
.circle-image {
    display: block;
    margin: 0 auto;
    max-width: 90%;
    max-height: 90%;
    width: 75vh;
    height: auto;
    border-radius: 50%;
}

.image-container {}

/* Ekran genişliği 768px'den küçük olduğunda stil değişikliği */
/* Ekran genişliği 768px'den küçük olduğunda stil değişikliği */
@media screen and (max-width: 768px) {
    .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* Dikeyde ortalamak için */
        height: 100vh;
        /* Ekranın tamamını kaplaması için */
    }

    .button-group {
        margin-top: 20px;
    }

    .button {
        width: 100%;
    }

    .welcome-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .circle-image {
        max-height: 70%;
        max-width: 70%;
        margin: 0 auto;
        /* Yatayda ekranın ortasına gelmesi için */
        border-radius: 50%;
    }
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}